import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  getAccurType,
  getCDopuskType,
  getDefaultAlertProps,
  getOutputType,
  getPoddiapazonType,
  getPreobrType,
  getTempType,
  getUnitType,
} from '../../../utils/utils';
import { MyAlert } from '../../UI/MyAlert';
import { MyTextFiled } from '../../UI/MyTextField';
import { MyButton } from '../../UI/MyButton';
import { DeviceListDto } from '../../../dto/DeviceListDto';
import { IAlertProps } from '../../../interface/IAlertProps';
import { IApiResult } from '../../../interface/IApiResult';
import { apiUpdateReestr } from '../../../service/device/apiUpdateReestr';
import {
  API_STATUS_PROBLEM,
  MAIN_COLOR_STRING,
} from '../../../constant/constants';
import { AlertSeverityEnum } from '../../../enum/AlertSeverityEnum';
import { MyCheckBoxTask } from '../../UI/MyCheckBoxTask';
import { MyAutoComplete } from '../../UI/MuAutoComplete';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: DeviceListDto;
}

export const DialogReestrMoreInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  dto,
}) => {
  const [checkHelper, setCheckHelper] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => {
    setIsEdit(true);
  };

  const preobrTypes = getPreobrType();
  const dopuskTypes = getCDopuskType();
  const tempTypes = getTempType();
  const poddiapazonTypes = getPoddiapazonType();
  const [reeatrAdd, setReestAdd] = useState<DeviceListDto>({
    id: -1,
    fifoei: '',
    type: -1,
    name: '',
    model: '',
    npi: '',
    vpi: '',
    unit: -1,
    mainAccur: '',
    mainAccurType: -1,
    dopAccur: '',
    dopAccurType: -1,
    outputType: -1,
    tempType: -1,
    classDopuska: -1,
    name2: '',
    model2: '',
    fifoei2: '',
    poddiapazon: -1,
    mainAccur2: '',
    mainAccurType2: -1,
    mainAccur3: '',
    mainAccurType3: -1,
    mainAccur4: '',
    mainAccurType4: -1,
    npi2: '',
    vpi2: '',
    npi3: '',
    vpi3: '',
    npi4: '',
    vpi4: '',
    accurСalc: '',
    accurСalcType: -1,
    accurTime: '',
    accurTimeType: -1,
    isFlow: -1,
    isPres: -1,
    isTemp: -1,
    isCalc: -1,
    datettimeCreateTs: '',
    datetimeChangeTs: '',
    mainAccurTypeDescr: '',
    dopAccurTypeDescr: '',
    outputTypeDescr: '',
    tempTypeDescr: '',
    classDopuskaDescr: '',
    mainAccurType2Descr: '',
    mainAccurType3Descr: '',
    mainAccurType4Descr: '',
    accurCalcTypeDescr: '',
    accurTimeTypeDescr: '',
    unitDescr: '',
  });

  const handleClose = () => {
    onClose();
    setIsEdit(false);
    setCheckHelper(false);
  };

  const updateReestr = async () => {
    const apiResult: IApiResult = await apiUpdateReestr(reeatrAdd);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSave = async () => {
    if (
      reeatrAdd.fifoei === '' ||
      reeatrAdd.name === '' ||
      reeatrAdd.model === ''
    ) {
      setCheckHelper(true);
      return;
    }
    await updateReestr();
  };

  useEffect(() => {
    if (open) {
      setReestAdd({
        id: dto?.id ?? -1,
        fifoei: dto?.fifoei ?? '',
        type: dto?.type ?? -1,
        name: dto?.name ?? '',
        model: dto?.model ?? '',
        npi: dto?.npi ?? '',
        vpi: dto?.vpi ?? '',
        unit: dto?.unit ?? -1,
        mainAccur: dto?.mainAccur ?? '',
        mainAccurType: dto?.mainAccurType ?? -1,
        dopAccur: dto?.dopAccur ?? '',
        dopAccurType: dto?.dopAccurType ?? -1,
        outputType: dto?.outputType ?? -1,
        tempType: dto?.tempType ?? -1,
        classDopuska: dto?.classDopuska ?? -1,
        name2: dto?.name2 ?? '',
        model2: dto?.model2 ?? '',
        fifoei2: dto?.fifoei2 ?? '',
        poddiapazon: dto?.poddiapazon ?? -1,
        mainAccur2: dto?.mainAccur2 ?? '',
        mainAccurType2: dto?.mainAccurType2 ?? -1,
        mainAccur3: dto?.mainAccur3 ?? '',
        mainAccurType3: dto?.mainAccurType3 ?? -1,
        mainAccur4: dto?.mainAccur4 ?? '',
        mainAccurType4: dto?.mainAccurType4 ?? -1,
        npi2: dto?.npi2 ?? '',
        vpi2: dto?.vpi2 ?? '',
        npi3: dto?.npi3 ?? '',
        vpi3: dto?.vpi3 ?? '',
        npi4: dto?.npi4 ?? '',
        vpi4: dto?.vpi4 ?? '',
        accurСalc: dto?.accurСalc ?? '',
        accurСalcType: dto?.accurСalcType ?? -1,
        accurTime: dto?.accurTime ?? '',
        accurTimeType: dto?.accurTimeType ?? -1,
        isFlow: dto?.isFlow ?? -1,
        isPres: dto?.isPres ?? -1,
        isTemp: dto?.isTemp ?? -1,
        isCalc: dto?.isCalc ?? -1,
        datettimeCreateTs: dto?.datettimeCreateTs ?? '',
        datetimeChangeTs: dto?.datetimeChangeTs ?? '',
        mainAccurTypeDescr: dto?.mainAccurTypeDescr ?? '',
        dopAccurTypeDescr: dto?.dopAccurTypeDescr ?? '',
        outputTypeDescr: dto?.outputTypeDescr ?? '',
        tempTypeDescr: dto?.tempTypeDescr ?? '',
        classDopuskaDescr: dto?.classDopuskaDescr ?? '',
        mainAccurType2Descr: dto?.mainAccurType2Descr ?? '',
        mainAccurType3Descr: dto?.mainAccurType3Descr ?? '',
        mainAccurType4Descr: dto?.mainAccurType4Descr ?? '',
        accurCalcTypeDescr: dto?.accurCalcTypeDescr ?? '',
        accurTimeTypeDescr: dto?.accurTimeTypeDescr ?? '',
        unitDescr: dto?.unitDescr ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{ sx: { backgroundColor: '#e7ebee', width: '100%' } }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '24px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Подробная информация
              </Typography>
              {!isEdit && (
                <IconButton size="medium" onClick={handleEdit}>
                  <EditIcon
                    fontSize="medium"
                    sx={{ color: MAIN_COLOR_STRING }}
                  />
                </IconButton>
              )}
            </Stack>
            <IconButton size="medium" onClick={() => handleClose()}>
              <CloseIcon fontSize="medium" sx={{ color: MAIN_COLOR_STRING }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Stack spacing={2}>
            <Stack width="100%" direction="row" spacing={1}>
              <MyAutoComplete
                value={
                  reeatrAdd.type !== undefined && reeatrAdd.type !== -1
                    ? preobrTypes[reeatrAdd.type]
                    : ''
                }
                onChange={(e, v) =>
                  setReestAdd({
                    ...reeatrAdd,
                    type: preobrTypes.indexOf(v),
                  })
                }
                error={checkHelper}
                disabled={!isEdit}
                arrayList={preobrTypes}
                size="small"
                label="Измерительный проебразователь"
              />
            </Stack>
            <Stack width="100%" direction="row" spacing={1}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Информация о средстве измерений
              </Typography>
            </Stack>
            {reeatrAdd.type === 3 && (
              <Stack width="100%" direction="row" spacing={1}>
                <MyAutoComplete
                  value={
                    reeatrAdd.tempType !== undefined &&
                    reeatrAdd.tempType !== -1
                      ? tempTypes[reeatrAdd.tempType]
                      : ''
                  }
                  onChange={(e, v) =>
                    setReestAdd({
                      ...reeatrAdd,
                      tempType: tempTypes.indexOf(v),
                      tempTypeDescr: v,
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  arrayList={tempTypes}
                  size="small"
                  label="Тип"
                />
              </Stack>
            )}
            {reeatrAdd.type === 3 && reeatrAdd.tempType === 1 && (
              <Stack width="100%" direction="row" spacing={1}>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    letterSpacing: 1,
                    color: '#000000',
                  }}
                  component="text"
                >
                  Термопреобразователь сопротивления
                </Typography>
              </Stack>
            )}
            <Stack width="100%" direction="row" spacing={1}>
              <MyTextFiled
                value={reeatrAdd.name}
                onChange={(e) =>
                  setReestAdd({
                    ...reeatrAdd,
                    name: e.target.value,
                  })
                }
                error={checkHelper}
                disabled={!isEdit}
                label="Наименование"
                size="small"
              />
              <MyTextFiled
                value={reeatrAdd.model}
                width="600px"
                onChange={(e) =>
                  setReestAdd({
                    ...reeatrAdd,
                    model: e.target.value,
                  })
                }
                error={checkHelper}
                disabled={!isEdit}
                label="Модель"
                size="small"
              />
              <MyTextFiled
                value={reeatrAdd.fifoei}
                width="400px"
                onChange={(e) =>
                  setReestAdd({
                    ...reeatrAdd,
                    fifoei: e.target.value,
                  })
                }
                error={checkHelper}
                disabled={!isEdit}
                label="№ ФИФОЕИ"
                size="small"
              />
            </Stack>
            {reeatrAdd.type === 3 && reeatrAdd.tempType === 1 && (
              <Stack width="100%" direction="row" spacing={1}>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    letterSpacing: 1,
                    color: '#000000',
                  }}
                  component="text"
                >
                  Нормирующий преобразователь
                </Typography>
              </Stack>
            )}
            {reeatrAdd.type === 3 && reeatrAdd.tempType === 1 && (
              <Stack width="100%" direction="row" spacing={1}>
                <MyTextFiled
                  value={reeatrAdd.name2}
                  onChange={(e) =>
                    setReestAdd({
                      ...reeatrAdd,
                      name2: e.target.value,
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  label="Наименование"
                  size="small"
                />
                <MyTextFiled
                  value={reeatrAdd.model2}
                  width="600px"
                  onChange={(e) =>
                    setReestAdd({
                      ...reeatrAdd,
                      model2: e.target.value,
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  label="Модель"
                  size="small"
                />
                <MyTextFiled
                  value={reeatrAdd.fifoei2}
                  width="400px"
                  onChange={(e) =>
                    setReestAdd({
                      ...reeatrAdd,
                      fifoei2: e.target.value,
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  label="№ ФИФОЕИ"
                  size="small"
                />
              </Stack>
            )}
            <Stack width="100%" direction="row" spacing={1}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  letterSpacing: 1,
                  color: '#000000',
                }}
                component="text"
              >
                Метрологические и технические характеристики
              </Typography>
            </Stack>
            {reeatrAdd.type !== 4 && reeatrAdd.type !== 5 && (
              <Stack width="100%" direction="row" spacing={1}>
                <MyTextFiled
                  value={reeatrAdd.npi}
                  onChange={(e) =>
                    setReestAdd({
                      ...reeatrAdd,
                      npi: e.target.value,
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  type="number"
                  label="Нижний предел измерений"
                  size="small"
                />
                <MyTextFiled
                  value={reeatrAdd.vpi}
                  onChange={(e) =>
                    setReestAdd({
                      ...reeatrAdd,
                      vpi: e.target.value,
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  type="number"
                  label="Верхний предел измерений"
                  size="small"
                />
                <MyAutoComplete
                  value={
                    reeatrAdd.unit !== undefined && reeatrAdd.unit !== -1
                      ? getUnitType(reeatrAdd.type)[reeatrAdd.unit]
                      : ''
                  }
                  onChange={(e, v) =>
                    setReestAdd({
                      ...reeatrAdd,
                      unit: getUnitType(reeatrAdd.type).indexOf(v),
                      unitDescr: v,
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  arrayList={getUnitType(reeatrAdd.type)}
                  size="small"
                  label="Единица измерений"
                />
                <MyAutoComplete
                  value={
                    reeatrAdd.outputType !== undefined &&
                    reeatrAdd.outputType !== -1
                      ? getOutputType(reeatrAdd.type)[reeatrAdd.outputType]
                      : ''
                  }
                  onChange={(e, v) =>
                    setReestAdd({
                      ...reeatrAdd,
                      outputType: getOutputType(reeatrAdd.type).indexOf(v),
                      outputTypeDescr: v,
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  arrayList={getOutputType(reeatrAdd.type)}
                  size="small"
                  label="Выходной сигнал"
                />
              </Stack>
            )}
            {(reeatrAdd.type === 4 || reeatrAdd.type === 5) && (
              <Stack width="100%" direction="row" spacing={1}>
                {reeatrAdd.type === 5 && (
                  <MyCheckBoxTask
                    value={reeatrAdd.isCalc === 1}
                    onChange={(e) =>
                      setReestAdd({
                        ...reeatrAdd,
                        isCalc: e.target.checked ? 1 : 0,
                      })
                    }
                    disabled={!isEdit}
                    label="Погрешность вычисления и времени"
                  />
                )}
                {reeatrAdd.isCalc !== 1 && (
                  <MyCheckBoxTask
                    value={reeatrAdd.isFlow === 1}
                    onChange={(e) =>
                      setReestAdd({
                        ...reeatrAdd,
                        isFlow: e.target.checked ? 1 : 0,
                      })
                    }
                    disabled={!isEdit}
                    label="Канал расхода"
                  />
                )}
                {reeatrAdd.isCalc !== 1 && (
                  <MyCheckBoxTask
                    value={reeatrAdd.isPres === 1}
                    onChange={(e) =>
                      setReestAdd({
                        ...reeatrAdd,
                        isPres: e.target.checked ? 1 : 0,
                      })
                    }
                    disabled={!isEdit}
                    label="Канал давления"
                  />
                )}
                {reeatrAdd.isCalc !== 1 && (
                  <MyCheckBoxTask
                    value={reeatrAdd.isTemp === 1}
                    onChange={(e) =>
                      setReestAdd({
                        ...reeatrAdd,
                        isTemp: e.target.checked ? 1 : 0,
                      })
                    }
                    disabled={!isEdit}
                    label="Канал температуры"
                  />
                )}
              </Stack>
            )}
            {reeatrAdd.type === 3 && (
              <Stack width="100%" direction="row" spacing={1}>
                <MyAutoComplete
                  width="50%"
                  value={
                    reeatrAdd.classDopuska !== undefined &&
                    reeatrAdd.classDopuska !== -1
                      ? dopuskTypes[reeatrAdd.classDopuska]
                      : ''
                  }
                  onChange={(e, v) =>
                    setReestAdd({
                      ...reeatrAdd,
                      classDopuska: dopuskTypes.indexOf(v),
                      classDopuskaDescr: v,
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  arrayList={dopuskTypes}
                  size="small"
                  label="Класс допуска"
                />
              </Stack>
            )}
            {reeatrAdd.type === 0 && (
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                spacing={1}
              >
                <div> </div>
                <MyAutoComplete
                  width="350px"
                  value={
                    reeatrAdd.poddiapazon !== undefined &&
                    reeatrAdd.poddiapazon !== -1
                      ? poddiapazonTypes[reeatrAdd.poddiapazon]
                      : ''
                  }
                  onChange={(e, v) =>
                    setReestAdd({
                      ...reeatrAdd,
                      poddiapazon: poddiapazonTypes.indexOf(v),
                    })
                  }
                  error={checkHelper}
                  disabled={!isEdit}
                  arrayList={poddiapazonTypes}
                  size="small"
                  label="Поддиапазоны"
                />
              </Stack>
            )}
            {((reeatrAdd.type === 5 && reeatrAdd.isCalc !== 1) ||
              reeatrAdd.type !== 5) && (
              <Stack spacing={1}>
                {reeatrAdd.type !== 0 && (
                  <Stack width="100%" direction="row" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: 'sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        letterSpacing: 1,
                        color: '#000000',
                      }}
                      component="text"
                    >
                      Основная погрешность
                    </Typography>
                  </Stack>
                )}
                {reeatrAdd.type === 0 && (
                  <Stack width="100%" direction="row" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: 'sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        letterSpacing: 1,
                        color: '#000000',
                      }}
                      component="text"
                    >
                      Погрешность в диапазоне
                    </Typography>
                  </Stack>
                )}
                <Stack width="100%" direction="row" spacing={1}>
                  {reeatrAdd.type === 0 && (
                    <MyTextFiled
                      value={reeatrAdd.npi}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          npi: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="От (включительно)"
                      size="small"
                    />
                  )}
                  {reeatrAdd.type === 0 && (
                    <MyTextFiled
                      value={
                        reeatrAdd.poddiapazon === 0
                          ? reeatrAdd.vpi
                          : reeatrAdd.vpi2
                      }
                      onChange={(e) =>
                        setReestAdd(
                          reeatrAdd.poddiapazon === 0
                            ? {
                                ...reeatrAdd,
                                vpi: e.target.value,
                              }
                            : {
                                ...reeatrAdd,
                                vpi2: e.target.value,
                              }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label={
                        reeatrAdd.poddiapazon === 0
                          ? 'До (включительно)'
                          : 'До (исключительно)'
                      }
                      size="small"
                    />
                  )}
                  <MyAutoComplete
                    width={reeatrAdd.type === 3 ? '50%' : undefined}
                    value={
                      reeatrAdd.mainAccurType !== undefined &&
                      reeatrAdd.mainAccurType !== -1
                        ? getAccurType(reeatrAdd.type, false)[
                            reeatrAdd.mainAccurType
                          ]
                        : ''
                    }
                    onChange={(e, v) =>
                      setReestAdd({
                        ...reeatrAdd,
                        mainAccurType: getAccurType(
                          reeatrAdd.type,
                          false
                        ).indexOf(v),
                        mainAccurTypeDescr: v,
                      })
                    }
                    error={checkHelper}
                    disabled={!isEdit}
                    arrayList={getAccurType(reeatrAdd.type, false)}
                    size="small"
                    label="Тип погрешности"
                  />
                  {((reeatrAdd.mainAccurType !== 0 && reeatrAdd.type === 3) ||
                    reeatrAdd.type !== 3) && (
                    <MyTextFiled
                      width={reeatrAdd.type === 3 ? '50%' : undefined}
                      value={reeatrAdd.mainAccur}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          mainAccur: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="Погрешность"
                      size="small"
                    />
                  )}
                </Stack>
                {reeatrAdd.type === 0 && reeatrAdd.poddiapazon > 0 && (
                  <Stack width="100%" direction="row" spacing={1}>
                    <MyTextFiled
                      value={reeatrAdd.vpi2}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          vpi2: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="От (включительно)"
                      size="small"
                    />
                    <MyTextFiled
                      value={
                        reeatrAdd.poddiapazon === 1
                          ? reeatrAdd.vpi
                          : reeatrAdd.vpi3
                      }
                      onChange={(e) =>
                        setReestAdd(
                          reeatrAdd.poddiapazon === 1
                            ? {
                                ...reeatrAdd,
                                vpi: e.target.value,
                              }
                            : {
                                ...reeatrAdd,
                                vpi3: e.target.value,
                              }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label={
                        reeatrAdd.poddiapazon === 1
                          ? 'До (включительно)'
                          : 'До (исключительно)'
                      }
                      size="small"
                    />
                    <MyAutoComplete
                      value={
                        reeatrAdd.mainAccurType2 !== undefined &&
                        reeatrAdd.mainAccurType2 !== -1
                          ? getAccurType(reeatrAdd.type, false)[
                              reeatrAdd.mainAccurType2
                            ]
                          : ''
                      }
                      onChange={(e, v) =>
                        setReestAdd({
                          ...reeatrAdd,
                          mainAccurType2: getAccurType(
                            reeatrAdd.type,
                            false
                          ).indexOf(v),
                          mainAccurType2Descr: v,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      arrayList={getAccurType(reeatrAdd.type, false)}
                      size="small"
                      label="Тип погрешности"
                    />
                    <MyTextFiled
                      value={reeatrAdd.mainAccur2}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          mainAccur2: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="Погрешность"
                      size="small"
                    />
                  </Stack>
                )}
                {reeatrAdd.type === 0 && reeatrAdd.poddiapazon > 1 && (
                  <Stack width="100%" direction="row" spacing={1}>
                    <MyTextFiled
                      value={reeatrAdd.vpi3}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          vpi3: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="От (включительно)"
                      size="small"
                    />
                    <MyTextFiled
                      value={
                        reeatrAdd.poddiapazon === 2
                          ? reeatrAdd.vpi
                          : reeatrAdd.vpi4
                      }
                      onChange={(e) =>
                        setReestAdd(
                          reeatrAdd.poddiapazon === 2
                            ? {
                                ...reeatrAdd,
                                vpi: e.target.value,
                              }
                            : {
                                ...reeatrAdd,
                                vpi4: e.target.value,
                              }
                        )
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label={
                        reeatrAdd.poddiapazon === 2
                          ? 'До (включительно)'
                          : 'До (исключительно)'
                      }
                      size="small"
                    />
                    <MyAutoComplete
                      value={
                        reeatrAdd.mainAccurType3 !== undefined &&
                        reeatrAdd.mainAccurType3 !== -1
                          ? getAccurType(reeatrAdd.type, false)[
                              reeatrAdd.mainAccurType3
                            ]
                          : ''
                      }
                      onChange={(e, v) =>
                        setReestAdd({
                          ...reeatrAdd,
                          mainAccurType3: getAccurType(
                            reeatrAdd.type,
                            false
                          ).indexOf(v),
                          mainAccurType3Descr: v,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      arrayList={getAccurType(reeatrAdd.type, false)}
                      size="small"
                      label="Тип погрешности"
                    />
                    <MyTextFiled
                      value={reeatrAdd.mainAccur3}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          mainAccur3: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="Погрешность"
                      size="small"
                    />
                  </Stack>
                )}
                {reeatrAdd.type === 0 && reeatrAdd.poddiapazon > 2 && (
                  <Stack width="100%" direction="row" spacing={1}>
                    <MyTextFiled
                      value={reeatrAdd.vpi4}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          vpi4: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="От (включительно)"
                      size="small"
                    />
                    <MyTextFiled
                      value={reeatrAdd.vpi}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          vpi: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="До (включительно)"
                      size="small"
                    />
                    <MyAutoComplete
                      value={
                        reeatrAdd.mainAccurType4 !== undefined &&
                        reeatrAdd.mainAccurType4 !== -1
                          ? getAccurType(reeatrAdd.type, false)[
                              reeatrAdd.mainAccurType4
                            ]
                          : ''
                      }
                      onChange={(e, v) =>
                        setReestAdd({
                          ...reeatrAdd,
                          mainAccurType4: getAccurType(
                            reeatrAdd.type,
                            false
                          ).indexOf(v),
                          mainAccurType4Descr: v,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      arrayList={getAccurType(reeatrAdd.type, false)}
                      size="small"
                      label="Тип погрешности"
                    />
                    <MyTextFiled
                      value={reeatrAdd.mainAccur4}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          mainAccur4: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="Погрешность"
                      size="small"
                    />
                  </Stack>
                )}
                <Stack width="100%" direction="row" spacing={1}>
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      letterSpacing: 1,
                      color: '#000000',
                    }}
                    component="text"
                  >
                    Дополнительная погрешность
                  </Typography>
                </Stack>
                <Stack width="100%" direction="row" spacing={1}>
                  <MyAutoComplete
                    width="50%"
                    value={
                      reeatrAdd.dopAccurType !== undefined &&
                      reeatrAdd.dopAccurType !== -1
                        ? getAccurType(reeatrAdd.type, true)[
                            reeatrAdd.dopAccurType
                          ]
                        : ''
                    }
                    onChange={(e, v) =>
                      setReestAdd({
                        ...reeatrAdd,
                        dopAccurType: getAccurType(
                          reeatrAdd.type,
                          true
                        ).indexOf(v),
                        dopAccurTypeDescr: v,
                      })
                    }
                    error={checkHelper}
                    disabled={!isEdit}
                    arrayList={getAccurType(reeatrAdd.type, true)}
                    size="small"
                    label="Тип погрешности"
                  />
                  {reeatrAdd.dopAccurType !== 0 && (
                    <MyTextFiled
                      width="50%"
                      value={reeatrAdd.dopAccur}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          dopAccur: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      label="Погрешность"
                      type="number"
                      size="small"
                    />
                  )}
                </Stack>
              </Stack>
            )}

            {reeatrAdd.type === 5 && reeatrAdd.isCalc === 1 && (
              <Stack spacing={1}>
                <Stack width="100%" direction="row" spacing={1}>
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      letterSpacing: 1,
                      color: '#000000',
                    }}
                    component="text"
                  >
                    Погрешность измерения времени
                  </Typography>
                </Stack>
                <Stack width="100%" direction="row" spacing={1}>
                  <MyAutoComplete
                    width="50%"
                    value={
                      reeatrAdd.accurTimeType !== undefined &&
                      reeatrAdd.accurTimeType !== -1
                        ? getAccurType(reeatrAdd.type, true, true)[
                            reeatrAdd.accurTimeType
                          ]
                        : ''
                    }
                    onChange={(e, v) =>
                      setReestAdd({
                        ...reeatrAdd,
                        accurTimeType: getAccurType(
                          reeatrAdd.type,
                          true,
                          true
                        ).indexOf(v),
                        accurTimeTypeDescr: v,
                      })
                    }
                    error={checkHelper}
                    disabled={!isEdit}
                    arrayList={getAccurType(reeatrAdd.type, true, true)}
                    size="small"
                    label="Тип погрешности"
                  />
                  {reeatrAdd.accurTimeType !== 0 && (
                    <MyTextFiled
                      width="50%"
                      value={reeatrAdd.accurTime}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          accurTime: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      type="number"
                      label="Погрешность"
                      size="small"
                    />
                  )}
                </Stack>
                <Stack width="100%" direction="row" spacing={1}>
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      letterSpacing: 1,
                      color: '#000000',
                    }}
                    component="text"
                  >
                    Погрешность вычисления
                  </Typography>
                </Stack>
                <Stack width="100%" direction="row" spacing={1}>
                  <MyAutoComplete
                    width="50%"
                    value={
                      reeatrAdd.accurСalcType !== undefined &&
                      reeatrAdd.accurСalcType !== -1
                        ? getAccurType(reeatrAdd.type, true, true)[
                            reeatrAdd.accurСalcType
                          ]
                        : ''
                    }
                    onChange={(e, v) =>
                      setReestAdd({
                        ...reeatrAdd,
                        accurСalcType: getAccurType(
                          reeatrAdd.type,
                          true,
                          true
                        ).indexOf(v),
                        accurCalcTypeDescr: v,
                      })
                    }
                    error={checkHelper}
                    disabled={!isEdit}
                    arrayList={getAccurType(reeatrAdd.type, true, true)}
                    size="small"
                    label="Тип погрешности"
                  />
                  {reeatrAdd.accurСalcType !== 0 && (
                    <MyTextFiled
                      width="50%"
                      value={reeatrAdd.accurСalc}
                      onChange={(e) =>
                        setReestAdd({
                          ...reeatrAdd,
                          accurСalc: e.target.value,
                        })
                      }
                      error={checkHelper}
                      disabled={!isEdit}
                      label="Погрешность"
                      type="number"
                      size="small"
                    />
                  )}
                </Stack>
              </Stack>
            )}
            <Stack direction="row" spacing={1}>
              <MyButton
                text="Отмена"
                background={MAIN_COLOR_STRING}
                onClick={() => setIsEdit(false)}
              />
              <MyButton
                text="Сохранить"
                background={MAIN_COLOR_STRING}
                onClick={handleSave}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogReestrMoreInfo.defaultProps = {
  dto: undefined,
};
