import React, { FunctionComponent } from 'react';
import classes from './SignInPageLayout.module.css';

interface Props {
  navigationBar?: React.ReactNode;
  centerDesktop?: React.ReactNode;
}

export const SignInPageLayout: FunctionComponent<Props> = ({
  navigationBar,
  centerDesktop,
}) => (
  <div style={{ userSelect: 'none' }} className={classes.myDiv}>
    <div className={classes.container}>
      <div className={classes.elementBar}>{navigationBar}</div>
      <div className={classes.elementRightHalf}>{centerDesktop}</div>
    </div>
  </div>
);

SignInPageLayout.defaultProps = {
  navigationBar: undefined,
  centerDesktop: undefined,
};
