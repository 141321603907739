import React, { FunctionComponent } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

interface Props {
  open: boolean;
  progress?: number;
}

export const BackDropDiv: FunctionComponent<Props> = ({ open, progress }) => (
  <div>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      {progress === undefined && <CircularProgress color="inherit" />}
      {progress !== undefined && (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            color="inherit"
            size={50}
            variant="determinate"
            value={progress}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="inherit">
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        </Box>
      )}
    </Backdrop>
  </div>
);

BackDropDiv.defaultProps = {
  progress: undefined,
};
