import React from 'react';
import { DevicesDiv } from '../component/devices/DevicesDiv';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { MainPageLayout } from '../layout/MainPageLayout';

export const DevicesPage = () => (
  <MainPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<DevicesDiv />}
  />
);
