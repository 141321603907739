import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  Card,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  getDefaultAlertProps,
  getSelectedLng,
  getViewType,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  API_STATUS_PROBLEM,
  DEVICE_STATUS,
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PAGE_SIZE_DEVICES,
  PC_VIEW,
  START_PAGE_SIZE_DEVICES,
  STATE,
} from '../../constant/constants';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetReestr } from '../../service/device/apiGetDevices';
import { MyButton } from '../UI/MyButton';
import { DialogCreateReestr } from '../dialog/accountant/DialogCreateReestr';
import { apiDeleteReestr } from '../../service/device/apiDeleteReestr';
import { DialogConfirmation } from '../dialog/DialogConfirmation';
import { DialogReestrMoreInfo } from '../dialog/accountant/DialogReestrMoreInfo';

export const DevicesDiv = () => {
  const { search } = useParams();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [deviceDtos, setDeviceDtos] = useState<DeviceListDto[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const [reestrDelete, setReestrDeleteDto] = useState<DeviceListDto>({
    id: -1,
    datetimeChangeTs: '',
    datettimeCreateTs: '',
    dopAccur: '',
    dopAccurType: -1,
    fifoei: '',
    mainAccur: '',
    mainAccurType: -1,
    model: '',
    name: '',
    npi: '',
    unit: -1,
    outputType: -1,
    type: -1,
    vpi: '',
    tempType: -1,
    classDopuska: -1,
    name2: '',
    model2: '',
    fifoei2: '',
    poddiapazon: -1,
    mainAccur2: '',
    mainAccurType2: -1,
    mainAccur3: '',
    mainAccurType3: -1,
    mainAccur4: '',
    mainAccurType4: -1,
    npi2: '',
    vpi2: '',
    npi3: '',
    vpi3: '',
    npi4: '',
    vpi4: '',
    accurСalc: '',
    accurСalcType: -1,
    accurTime: '',
    accurTimeType: -1,
    isFlow: -1,
    isPres: -1,
    isTemp: -1,
    isCalc: -1,
    mainAccurTypeDescr: '',
    dopAccurTypeDescr: '',
    outputTypeDescr: '',
    tempTypeDescr: '',
    classDopuskaDescr: '',
    mainAccurType2Descr: '',
    mainAccurType3Descr: '',
    mainAccurType4Descr: '',
    accurCalcTypeDescr: '',
    accurTimeTypeDescr: '',
    unitDescr: '',
  });

  const title = localStorage.getItem(DEVICE_STATUS) || '';
  const state = localStorage.getItem(STATE) || '';

  const getDevices = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetReestr(state, search);
    if (apiResult.isSuccess) {
      setDeviceDtos(apiResult.data.reestr);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const deleteReestr = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const dto: DeviceListDto = reestrDelete;
    const apiResult: IApiResult = await apiDeleteReestr(dto);
    if (apiResult.isSuccess) {
      await getDevices();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const confirmDelete = (dto: DeviceListDto) => {
    setReestrDeleteDto(dto);
    setOpenDialogConfirmation(true);
  };

  const getMoreInformation = (dto: DeviceListDto) => {
    setReestrDeleteDto(dto);
    setOpenDialog(true);
  };

  useEffect(() => {
    if (!openDialog && !openDialogCreate) {
      getDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, openDialog, openDialogCreate]);

  const columns: GridColDef[] = [
    {
      field: 'fifoei',
      headerName: '№ ФИФОЕИ',
      renderHeader: () => <strong>№ ФИФОЕИ</strong>,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.fifoei,
    },
    {
      field: 'name',
      headerName: 'Наименование',
      renderHeader: () => <strong>Наименование</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'model',
      headerName: 'Модель',
      renderHeader: () => <strong>Модель</strong>,
      minWidth: 250,
      editable: false,
      renderCell: (row) => row.row.model,
    },
    {
      field: 'type',
      headerName: 'Тип',
      renderHeader: () => <strong>Тип</strong>,
      minWidth: 310,
      editable: false,
      renderCell: (row) => row.row.type,
    },
    {
      field: 'npi',
      headerName: 'НПИ',
      renderHeader: () => <strong>НПИ</strong>,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.npi,
    },
    {
      field: 'vpi',
      headerName: 'ВПИ',
      renderHeader: () => <strong>ВПИ</strong>,
      minWidth: 150,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.vpi,
    },
    {
      field: 'mainAccur',
      headerName: 'Основная погрешность',
      renderHeader: () => <strong>Погрешность</strong>,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.mainAccur,
    },
    {
      field: 'id',
      type: 'actions',
      headerName: '',
      width: 70,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center" spacing={1}>
          <IconButton
            title="Просмотр информации"
            size="small"
            onClick={() => getMoreInformation(row.row)}
          >
            <MoreVertIcon fontSize="small" sx={{ color: MAIN_COLOR_STRING }} />
          </IconButton>
          <IconButton
            title="Удалить"
            size="small"
            onClick={() => confirmDelete(row.row)}
          >
            <DeleteForeverIcon fontSize="small" sx={{ color: 'red' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        marginBottom="20px"
        spacing={2}
      >
        <Stack width="90%">
          <BodyHeaderDiv title={title} />
          <Stack alignItems="flex-end">
            <MyButton
              text="Добавить"
              width="200px"
              background={MAIN_COLOR_STRING}
              onClick={() => setOpenDialogCreate(true)}
            />
          </Stack>
        </Stack>
        <Stack width="90%" spacing={1}>
          <Card
            style={{ padding: '20px', height: '100%', background: '#f5f5f5' }}
          >
            <Box
              height="100%"
              sx={{
                '& .app-theme-cell': {
                  fontFamily: 'sans-serif',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    background: '#f5f5f5',
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                      fontSize: '100px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '5px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={deviceDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={PAGE_SIZE_DEVICES}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </Card>
        </Stack>
      </Stack>
      <DialogCreateReestr
        open={openDialogCreate}
        onClose={() => setOpenDialogCreate(false)}
      />
      <DialogReestrMoreInfo
        open={openDialog}
        dto={reestrDelete}
        onClose={() => setOpenDialog(false)}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        onClose={deleteReestr}
      />
    </div>
  );
};
